<script setup lang="ts">
  import {computed, ref} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpProductImage from '~/components/cp/CpProductImage/CpProductImage.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';

  const props = withDefaults(defineProps<{
    id: string,
    link: string,
    image: string,
    title: string,
    size: 'large'|'medium'|'small',
    active?: boolean;
    halfSizeWidth?:boolean
  }>(), {
    active: false,
    halfSizeWidth: false,
  });
  const {bm, e, em} = useCpBem('cp-card-category');
  const hover = ref(false);
  const imageWidth = computed(() => {
      let width = 136;
      if (props.size === 'large') {
        width = 156;
      }
      return width;
  });
  const imageHeight = computed(() => {
      let height = 48;
    if (props.size === 'large') {
      height = 176;
    }
    if (props.size === 'medium') {
      height = 104;
    }
    return height;
  });
</script>

<template>
    <CpNuxtLink :class="bm({'half-size': halfSizeWidth})" page-name="catalog" :link="link">
        <div
            :class="em('container',{[size]: true, active: active || hover})"
            @mousedown="hover = true"
            @mouseup="hover = false"
        >
            <CpProductImage
                :class="em('image' ,{[size]:true})"
                :title="title"
                :picture="image"
                :width="imageWidth"
                :height="imageHeight"
            />
            <CpText
                :variant="size === 'small' ? 'caption-regular' : 'body-regular'"
                type="primary"
                :class="e('label')"
            >
                {{ title }}
            </CpText>
        </div>
    </CpNuxtLink>
</template>

<style scoped lang="scss">
    .cp-card-category {
      &__container {
        border: 1px solid var(--color-neutral-extra-light);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &--active {
          border: 1px solid var(--color-secondary);
        }
        &--large {
          height: 176px;
          flex-direction: column;
        }
        &--medium {
          height: 104px;
          width: 136px;
          flex-direction: column;
        }
        &--small {
          height: 48px;
          width: 136px;
          flex-direction: row;
          border-radius: 8px;
        }
      }
      &__label {
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
      }
      &__image {
        width: 100%;
        &--large {
          max-height: 120px;
        }
        &--medium {
          max-height: 48px;
        }
        &--small {
          max-height: 32px;
          max-width: 32px;
        }
      }
      &--half-size {
        width: calc(50% - 8px);
      }
    }
</style>
